import React from "react"

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import bg from "../../images/banners/about-team.jpg"
import BorderTitle from "./../../components/border-title/borderTitle"

import "./our-team.scss"
import PersonDescription from "./../../components/person-description/personDescription"
import StaffProfile from "./../../components/staff-profile/staffProfile"
import { aboutOurTeamPage } from "../../utils/text"

var OurTeam = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="our-team-page">
      <Banner
        src={bg}
        title={aboutOurTeamPage.banner.title}
        subTitle={aboutOurTeamPage.banner.subtitle}
        height="27.5rem"
        backgroundPosY="40%"
      />
      <PageBody className="page">
        {aboutOurTeamPage.groups.map(group => (
          <div className="group" key={group}>
            <BorderTitle>{group.groupName}</BorderTitle>
            <div className="cards">
              {group.people.map(person => (
                <div className="person-card" key={person.name}>
                  <h4>{person.name}</h4>
                  <h5 className="accent-dark">{person.title}</h5>
                </div>
              ))}
            </div>
          </div>
        ))}
      </PageBody>
    </div>
  </>
)

export default OurTeam
