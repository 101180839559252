import React from "react"

import "./fancyTitle.scss"

var FancyTitle = props => (
  <div className={`fancy-title-component ${props.className || ""}`}>
    <h4>
      {props.title} {props.subtitle && "|"}{" "}
      <span className="accent-dark">{props.subtitle}</span>
    </h4>
    {props.bullets &&
      props.bullets.map((bullet, index) => (
        <p key={bullet} className="bullet">
          {bullet}
          {index + 1 !== props.bullets.length ? (
            <span className="point"> · </span>
          ) : (
            ""
          )}
        </p>
      ))}
  </div>
)

export default FancyTitle
